var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box-header with-border"},[_c('h3',[_c('previous-route-block'),_vm._v(" "+_vm._s(_vm.page_title)+" "),_c('router-link',{staticClass:"btn btn-success pull-right",attrs:{"to":{ name: 'client.create' },"title":"Добавить","type":"button"}},[_c('i',{staticClass:"fa fa-plus"})])],1),_c('div',{staticClass:"form-group mt-3"},[_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"source_type-all","autocomplete":"off"},domProps:{"checked":_vm.filter_clients.source_type === null},on:{"input":() => {
               _vm.filter_clients.source_type = null;
               _vm.updateFilters();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"source_type-all"}},[_vm._v("Все")]),_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"source_type-car-owners","autocomplete":"off"},domProps:{"checked":_vm.filter_clients.source_type === 'car_owners'},on:{"input":() => {
               _vm.filter_clients.source_type = 'car_owners';
               _vm.updateFilters();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"source_type-car-owners"}},[_vm._v("Владелец авто")]),_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"source_type-site","autocomplete":"off"},domProps:{"checked":_vm.filter_clients.source_type === 'site'},on:{"input":() => {
               _vm.filter_clients.source_type = 'site';
               _vm.updateFilters();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"source_type-site"}},[_vm._v("С сайта")]),_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"source_type-partner","autocomplete":"off"},domProps:{"checked":_vm.filter_clients.source_type === 'partner'},on:{"input":() => {
               _vm.filter_clients.source_type = 'partner';
               _vm.updateFilters();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"source_type-partner"}},[_vm._v("Приглашен партнером")]),_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"source_type-incoming","autocomplete":"off"},domProps:{"checked":_vm.filter_clients.source_type === 'incoming'},on:{"input":() => {
               _vm.filter_clients.source_type = 'incoming';
               _vm.updateFilters();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"source_type-incoming"}},[_vm._v("Входящая линия")]),_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"source_type-by-employee","autocomplete":"off"},domProps:{"checked":_vm.filter_clients.source_type === 'by_employee'},on:{"input":() => {
               _vm.filter_clients.source_type = 'by_employee';
               _vm.updateFilters();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"source_type-by-employee"}},[_vm._v("Создан сотрудником")])])]),_c('div',{staticClass:"box-body"},[_c('table-items',{attrs:{"data_search":_vm.data_search,"filters_query":_vm.filter_clients,"relations":_vm.relations,"component":"client-item","columns":_vm.columns,"api_resource":"/clients"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }