<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}

        <router-link :to="{ name: 'client.create' }" class="btn btn-success pull-right"
                     title="Добавить" type="button">
          <i class="fa fa-plus"></i>
        </router-link>
      </h3>

      <div class="form-group mt-3">
        <input type="radio" class="btn-check" name="options-outlined" id="source_type-all"
               @input="() => {
                 filter_clients.source_type = null;
                 updateFilters();
               }" autocomplete="off" :checked="filter_clients.source_type === null">
        <label class="btn btn-outline-primary" for="source_type-all">Все</label>

        <input type="radio" class="btn-check" name="options-outlined" id="source_type-car-owners"
               @input="() => {
                 filter_clients.source_type = 'car_owners';
                 updateFilters();
               }" autocomplete="off" :checked="filter_clients.source_type === 'car_owners'">
        <label class="btn btn-outline-primary" for="source_type-car-owners">Владелец авто</label>

        <input type="radio" class="btn-check" name="options-outlined" id="source_type-site"
               @input="() => {
                 filter_clients.source_type = 'site';
                 updateFilters();
               }" autocomplete="off" :checked="filter_clients.source_type === 'site'">
        <label class="btn btn-outline-primary" for="source_type-site">С сайта</label>

        <input type="radio" class="btn-check" name="options-outlined" id="source_type-partner"
               @input="() => {
                 filter_clients.source_type = 'partner';
                 updateFilters();
               }" autocomplete="off" :checked="filter_clients.source_type === 'partner'">
        <label class="btn btn-outline-primary" for="source_type-partner">Приглашен партнером</label>

        <input type="radio" class="btn-check" name="options-outlined" id="source_type-incoming"
               @input="() => {
                 filter_clients.source_type = 'incoming';
                 updateFilters();
               }" autocomplete="off" :checked="filter_clients.source_type === 'incoming'">
        <label class="btn btn-outline-primary" for="source_type-incoming">Входящая линия</label>

        <input type="radio" class="btn-check" name="options-outlined" id="source_type-by-employee"
               @input="() => {
                 filter_clients.source_type = 'by_employee';
                 updateFilters();
               }" autocomplete="off" :checked="filter_clients.source_type === 'by_employee'">
        <label class="btn btn-outline-primary" for="source_type-by-employee">Создан сотрудником</label>
      </div>
    </div>

    <div class="box-body">
      <table-items :data_search="data_search"
                   :filters_query="filter_clients"
                   :relations="relations"
                   component="client-item"
                   :columns="columns"
                   api_resource="/clients">
      </table-items>
    </div>
  </div>

</template>

<script>
import Vue from 'vue';
import TableItems from './../../components/pagination/table-items';

Vue.component('client-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'clients',

  components: {
    TableItems,
  },

  props: {
    data_search: {
      type: String,
      required: false
    }
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Клиентская база',

    columns: [
      {
        title: 'ID',
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
      {
        title: 'Имя',
        class: null,
        style: null,
      },
      {
        title: 'Контакты',
        class: null,
        style: null,
      },
      {
        title: 'Последнее действие',
        class: null,
        style: null,
      },
      {
        title: 'Источник',
        class: null,
        style: null,
      },
      {
        title: 'Обновлён',
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
    ],
    relations: [
      'city',
      'history',
      'partner',
      'creator',
      'editor',
    ],

    filter_clients: {
      source_type: null,
    },
  }),

  methods: {
    updateFilters() {
      this.EventBus.$emit('table-filter-changed');
    },
  },
}
</script>

<style scoped>

</style>
